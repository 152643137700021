// Header.js
import React from "react";
import { Box, Toolbar, Typography, Divider } from "@mui/material";
import ic_back from "assets/images/ic_back.png";
import ic_logo from "assets/images/ic_logo2.png";
const Header = ({ title, onBackClick, type, hideBack }) => {
  return (
    <>
      <Box sx={{ width: "100%", backgroundColor: "primary.main" }}>
        <Toolbar
          sx={{
            minHeight: "60px !important",
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            backgroundColor: "#FFFFFF",
          }}
        >
          {type === "home" ? (
            <img
              src={ic_logo}
              style={{
                width: 32.65,
                height: 32,
              }}
            />
          ) : hideBack ? (
            <></>
          ) : (
            <img
              onClick={onBackClick}
              src={ic_back}
              style={{
                width: 24,
                height: 24,
              }}
            />
          )}
          <Typography
            // variant="h6"
            // component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              marginLeft: hideBack ? 8 :  4,
            }}
          >
            {title}
          </Typography>
          <Box sx={{ width: 48 }} /> {/* Spacer to balance the toolbar */}
        </Toolbar>
      </Box>
      <Divider />
    </>
  );
};

export default Header;
