import axios from "axios";
const BASE_URL = "https://api.dknyshop.com/";

class Network {
  static instance = new Network();
  token = localStorage.getItem("token");

  constructor() {
    if (Network.instance) {
      throw new Error(
        "Error: Instantiation failed: Use Network.getInstance() instead of new."
      );
    }
    Network.instance = this;
  }

  static getInstance() {
    return Network.instance;
  }

  getBaseUrl() {
    return BASE_URL;
  }

  setToken(token) {
    this.token = token;
  }

  unAuthorizedRequest(url, method = "GET", data, header, baseURL) {
    const response = axios({
      method: method,
      url: url,
      baseURL: baseURL ? baseURL : BASE_URL,
      data: data ? data : null,
      timeout: 60000,
      headers: {
        ...header,
        "Content-Type": "application/json",
      },
    });
    return response;
  }

  authorizedRequest(url, method = "GET", data, params, header) {
    const response = axios({
      method: method,
      url: url,
      baseURL: BASE_URL,
      data: data ? data : undefined,
      params: params ? params : null,
      timeout: 60000,
      headers: {
        ...header,
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    return response;
  }
}

axios.interceptors.request.use(
  function (config) {
    const { url, method, data, params, baseURL } = config;
    const message = {
      METHOD: method,
      HEADER: config.headers,
      DATA: data,
      PARAMS: params,
      URL: baseURL + url,
    };
    console.log(
      "%c [HTTP Interceptor Request]",
      "color: blue; font-weight: bold",
      message
    );

    return config;
  },
  function (error) {
    console.log(
      "%c [HTTP Interceptor Request Error]",
      "color: red; font-weight: bold",
      error
    );

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    const data = response?.data;
    const config = response?.config;
    const url = config?.url;
    const method = config?.method;
    const message = {
      METHOD: method,
      DATA: data,
      URL: url,
    };
    console.log(
      "%c [HTTP Interceptor Response]",
      "color: #248c1d; font-weight: bold",
      message
    );

    return data;
  },
  function (error) {
    console.log(
      "%c [HTTP Interceptor Response Error]",
      "color: red; font-weight: bold",
      error.response.data
    );

    return error.response.data;
  }
);

export default Network.getInstance();
