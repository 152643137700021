import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { closeSnackbar } from "reducers/snackbarReducer";
const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { open, message } = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (open) {
      toast(message, {
        onClose: () => dispatch(closeSnackbar()),
      });
    }
  }, [open, message, dispatch]);

  return <ToastContainer theme="dark" position="top-center" autoClose={1000} />;
};

export default SnackbarComponent;
