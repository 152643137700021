import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import Header from "components/Header";
import Text from "components/Text/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Icon from "components/Image/Icon";
import ic_logo from "assets/images/ic_logo2.png";
import QRCode from "react-qr-code";
import ic_share_black from "assets/images/ic_share_black.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

const SharePage = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => state.profile?.profile);
  const navigate = useNavigate();
  const handleSaveImage = () => {
    navigate("/share-qr");
  };
  const handleCopy = () => {};

  function maskString(input) {
    if (input.length <= 4) return input;
    const start = input.slice(0, 2);
    const end = input.slice(-2);
    const masked = start + "**" + end;
    return masked;
  }
  return (
    <Box>
      <Header
        title={t("share").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
        <Box
          style={{
            marginTop: 26,
            borderRadius: 8,
            backgroundColor: "#F4F4F4",
            justifyContent: "center", // Căn giữa theo chiều ngang
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: 12,
            paddingBottom: 20,
          }}
        >
          <Icon src={ic_logo} width={40} height={40} />

          <Text
            title={t("shareWithFriend")}
            style={{
              fontSize: 14,
              fontWeight: "bold",
              color: "#0A0A0A",
              marginTop: 4,
            }}
          />
          <Text
            title={t("scanforShare")}
            style={{
              fontSize: 12,
              color: "#717171",
              marginTop: 4,
            }}
          />
          <Box
            style={{
              marginTop: 8,
              backgroundColor: "#FFFFFF",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 4,
            }}
          >
            <QRCode
              size={220}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={profile?.code}
              viewBox={`0 0 256 256`}
            />
          </Box>
        </Box>
        <Text
          title={t("refCode")}
          style={{ marginTop: 16, fontSize: 14, fontWeight: "bold" }}
        />
        <OutlinedInput
          readOnly
          fullWidth
          placeholder={maskString(profile?.code)}
          sx={{
            borderRadius: 2,
            width: "100%",
            height: 50,
            marginTop: 1,
            "& input::placeholder": {
              color: "#333333", // Thay đổi màu placeholder ở đây
              opacity: 1, // Đảm bảo độ mờ của placeholder (1 là không mờ)
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <img
                src={ic_share_black}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            marginTop: 10,
          }}
        >
          <Box
            onClick={handleSaveImage}
            sx={{
              width: "48%",
              border: "1px solid #CECECE",
              color: "#0A0A0A",
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              borderRadius: 2,
            }}
          >
            <Text
              title={t("savePicture")}
              style={{ color: "#0A0A0A", fontSize: 14, fontWeight: "500" }}
            />
          </Box>
          <CopyToClipboard text={profile?.code} onCopy={handleCopy}>
            <Box
              sx={{
                width: "48%",
                backgroundColor: "#333333",
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: 2,
              }}
            >
              <Text
                title={t("copy")}
                style={{ color: "#FFFFFF", fontSize: 14, fontWeight: "500" }}
              />
            </Box>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
};

export default SharePage;
