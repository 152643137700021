import { Box, Container, Link, Button,Typography, Avatar } from "@mui/material"
import { useNavigate } from "react-router-dom";
import bg_splash from "assets/images/bg_splash.png";
import ic_logo from "assets/images/ic_logo.png";
import rating_gr from 'assets/images/rating_gr.png'

const SplashPage = () => {
  const navigate = useNavigate();
    return (
        <Container maxWidth="sm" style={{paddingLeft: 0, paddingRight: 0}}>
            <Box
                sx={{
                    backgroundImage: `url(${bg_splash})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container style={{marginTop: '30vh'}}>
                    <Avatar src={ic_logo} sx={{ height: '80px', width: '80px' }}></Avatar>
                    <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 700, fontSize: 25, color: 'white', marginTop: '20px'}}>
                        Create an Account
                    </Typography>
                    <Typography variant="body2" style={{fontWeight: 400, fontSize: 16, color: 'white'}}>
                    Sign up to DKNY Online Store and enjoy the benefits: Latest News & Exclusive Offers; OrderHistory & Address Book; Save items in yourWishlist and much more.
                    </Typography>
                    <img
                        src={rating_gr}
                        style={{ width: "100%", height: "auto", marginTop: '15px' }}
                        />
                </Container>
            </Box>
            <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" align="center">
          Welcome to DKNY
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary">
          Luxury shopping without limits
        </Typography>
      </Box>
    
      <Button
        type="submit"
        fullWidth
        onClick={() => navigate('/login')}
        variant="contained"
        style={{
          height: 45,
          borderRadius: 4,
          marginTop: 10,
          backgroundColor: "#333333",
          color: "#FFFFFF",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "500",
        }}
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <Button
        type="submit"
        fullWidth
        onClick={() => navigate('/register')}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        style={{
          height: 45,
          borderRadius: 4,
          marginTop: 10,
          backgroundColor: "#ffffff",
          color: "#333333",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Sign UP
      </Button>
    </Container>
        </Container>
    )
}

export default SplashPage