import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  OutlinedInput,
  InputAdornment,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ic_language from "../../assets/images/ic_language.png";
import ic_logo from "../../assets/images/ic_logo2.png";
import ic_showpassword from "../../assets/images/ic_showpassword.png";
import ic_hidepassword from "../../assets/images/ic_hidepassword.png";
import ic_phone from "../../assets/images/ic_phone.png";
import { country } from "./country";

import apis from "services/apis";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducers/snackbarReducer";

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [phone, setPhone] = useState("+84");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordOrder, setPasswordOrder] = useState("");
  const [refcode, setRefcode] = useState("523834");
  const [isCheck, setIsCheck] = useState(false);

  const handleRegister = () => {
    if (!username) {
      dispatch(showSnackbar(t("enterAccount")));
      return;
    }

    if (isEmail) {
      if (!email) {
        dispatch(showSnackbar(t("enterEmail")));
        return;
      }

      if (email && !email.includes("@")) {
        dispatch(showSnackbar(t("wrongEmail")));
        return;
      }
    } else {
      if (!phoneNumber) {
        dispatch(showSnackbar(t("enterPhone")));
        return;
      }
    }

    if (!password) {
      dispatch(showSnackbar(t("enterPassword")));
      return;
    }

    if (!passwordOrder) {
      dispatch(showSnackbar(t("enterPasswordOrder")));
      return;
    }

    if (!refcode) {
      dispatch(showSnackbar(t("enterRefcode")));
      return;
    }
    if (!isCheck) {
      dispatch(showSnackbar(t("selectAgree")));
      return;
    }
    onSubmitRegister();
  };

  const onSubmitRegister = async () => {
    try {
      let params = {
        username: username.toLocaleLowerCase(),
        password: password,
        mobile: "1",
        passwordLevel2: passwordOrder,
        refCode: refcode,
      };

      if (isEmail) {
        params.email = email.toLocaleLowerCase();
      } else {
        params.mobile = phoneNumber;
      }
      const response = await apis.register(params);
      console.log("REGISTER", response);
      if (response?.results) {
        dispatch(showSnackbar(t("registerSuccess")));
        navigate("/");
      }
    } catch (error) {
      console.log("CHECK ERROR", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {};

  const onShowPhone = () => {
    console.log("AAAA");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const onSearchContry = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value !== "") {
      const data = country.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setDataSearch(data);
    }
  }, [value]);

  const renderItem = (item) => {
    return (
      <>
        <ListItem
          button
          onClick={() => {
            setPhone(item.dial_code);
            setOpen(false);
          }}
        >
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              marginBottom: 2,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography component="p" variant="body2">
              {item.name}
            </Typography>
            <Typography component="p" variant="body2">
              {item.dial_code}
            </Typography>
          </Box>
        </ListItem>
        <Divider />
      </>
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <img
            onClick={() => navigate("/language")}
            src={ic_language}
            style={{
              width: 24,
              height: 24,
            }}
          />
        </Box>
        <img
          src={ic_logo}
          style={{
            width: 60,
            height: 60,
            marginTop: 12,
          }}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginTop: 1,
            color: "#0A0A0A",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {t("register").toUpperCase()}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          sx={{
            color: "#717171",
            fontFamily: "Inter",
            fontSize: "12px",
          }}
        >
          {t("canUse")}{" "}
          <Link
            onClick={() => setIsEmail(!isEmail)}
            underline="hover"
            style={{
              color: "#000000",
              textDecoration: "underline",
              fontFamily: "Inter",
              fontSize: "12px",
            }}
          >
            {t("registerWith")}{" "}
            {isEmail ? t("withPhone") + " " : t("withEmail") + " "}
          </Link>
        </Typography>

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography
            component="p"
            variant="body2"
            sx={{
              marginBottom: 1,
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("nameAccount")}
          </Typography>
          <OutlinedInput
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            placeholder={t("nameAccount")}
            sx={{ borderRadius: 2, width: "100%", height: 50, marginBottom: 1 }}
          />
          {isEmail ? (
            <>
              <Typography
                component="p"
                variant="body2"
                sx={{
                  marginBottom: 1,
                  color: "#0A0A0A",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("email")}
              </Typography>
              <OutlinedInput
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                placeholder="name@example.com"
                sx={{ borderRadius: 2, width: "100%", height: 50 }}
              />
            </>
          ) : (
            <>
              <Typography
                component="p"
                variant="body2"
                sx={{
                  marginBottom: 1,
                  color: "#0A0A0A",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("phoneOrAccount")}
              </Typography>
              <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                PaperProps={{
                  sx: { width: "60%" },
                }}
              >
                <Box style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                  <OutlinedInput
                    fullWidth
                    placeholder="Search country/region"
                    onChange={onSearchContry}
                    style={{ borderRadius: 8, width: "100%", height: 50 }}
                  />
                  <List>
                    {value
                      ? dataSearch.map((item) => {
                          return renderItem(item);
                        })
                      : country.map((item) => {
                          return renderItem(item);
                        })}
                  </List>
                </Box>
              </Drawer>
              <OutlinedInput
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
                placeholder=""
                sx={{ borderRadius: 2, width: "100%", height: 50 }}
                startAdornment={
                  <InputAdornment position="start" onClick={toggleDrawer(true)}>
                    <img
                      src={ic_phone}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 4,
                      }}
                    />
                    <span style={{ marginLeft: 4, marginRight: 8 }}>
                      {phone} |
                    </span>
                  </InputAdornment>
                }
              />
            </>
          )}

          <Typography
            component="p"
            variant="body2"
            sx={{
              marginBottom: 1,
              marginTop: 2.5,
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("password")}
          </Typography>
          <OutlinedInput
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            placeholder={t("holderPassword")}
            type={showPassword ? "text" : "password"}
            sx={{ borderRadius: 2, width: "100%", height: 50 }}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <img
                    src={ic_hidepassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : (
                  <img
                    src={ic_showpassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
              </IconButton>
            }
          />

          <Typography
            component="p"
            variant="body2"
            sx={{
              marginBottom: 1,
              marginTop: 2.5,
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("passwordOrder")}
          </Typography>

          <OutlinedInput
            onChange={(e) => setPasswordOrder(e.target.value)}
            fullWidth
            placeholder={t("holderPasswordOrder")}
            type={showPassword ? "text" : "password"}
            sx={{ borderRadius: 2, width: "100%", height: 50 }}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <img
                    src={ic_hidepassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : (
                  <img
                    src={ic_showpassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
              </IconButton>
            }
          />

          <Typography
            component="p"
            variant="body2"
            sx={{
              marginBottom: 1,
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: 1,
            }}
          >
            {t("refcode")}
          </Typography>
          <OutlinedInput
            onChange={(e) => setRefcode(e.target.value)}
            fullWidth
            placeholder={t("holderRefcode")}
            sx={{ borderRadius: 2, width: "100%", height: 50 }}
          />

          <Box display="flex" style={{ marginTop: 16 }}>
            <Checkbox
              color="primary"
              onChange={(e) => setIsCheck(e.target.checked)}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#333333",
                fontFamily: "Inter",
                fontSize: "12px",
              }}
            >
              {t("accept")}{" "}
              <Link
                href="#"
                underline="hover"
                sx={{
                  color: "#B88C6D",
                  fontFamily: "Inter",
                  fontSize: "12px",
                }}
              >
                "Thỏa thuận mở tài khoản”
              </Link>{" "}
              {t("policy")}
            </Typography>
          </Box>

          <Button
            onClick={handleRegister}
            // type="submit"
            fullWidth
            variant="contained"
            style={{
              height: 45,
              borderRadius: 4,
              marginTop: 10,
              backgroundColor: "#333333",
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("register")}
          </Button>
          <Typography
            variant="body2"
            component="p"
            gutterBottom
            style={{
              width: "100%",
              marginTop: 16,
              justifyContent: "center",
              display: "flex",
              color: "#717171",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {t("haveAccount")}{" "}
            <Link
              onClick={() => navigate("/login")}
              underline="hover"
              style={{
                color: "#B88C6D",
                textDecoration: "underline",
                marginLeft: 2,
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {t("login")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterPage;
