import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Text from "components/Text/Typography";
const RulePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Header
        title={t("rule").toUpperCase()}
        onBackClick={() => {
          navigate(-1);
        }}
      />
      <Box
        style={{
          marginTop: 16,
          paddingBottom: 60,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Text
          title={t("levelIntroduction")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text title={t("level1")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level2")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level3")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level4")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level5")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level6")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level7")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level8")} style={{ fontSize: 14, color: "#333333" }} />
        <Text title={t("level9")} style={{ fontSize: 14, color: "#333333" }} />
        <Text
          title={t("operationSteps")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("luckyOrders")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("luckyOrder1")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("luckyOrder2")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("luckyOrder3")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("withdrawalTerms")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("shoppingPlatform")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("prohibitedItems")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("restrictedItems")}
          style={{ fontSize: 14, color: "#333333" }}
        />
        <Text
          title={t("intellectualProperty")}
          style={{ fontSize: 14, color: "#333333" }}
        />
      </Box>
    </Box>
  );
};
export default RulePage;
