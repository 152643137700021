import { Box, IconButton, OutlinedInput } from "@mui/material";
import React from "react";
import ic_send from "../../assets/images/ic_send.png";
import ic_show from "../../assets/images/ic_show.png";
const MessageInput = ({
  message,
  setMessage,
  sendMessage,
  inputRef,
  sendImage,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F4F4F4",
        padding: 1,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1, // Giữ input phía trên các phần tử khác
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FFFFFF",
          width: "100%",
          alignItems: "center",
          borderRadius: 4,
          height: 55,
          border: "1px solid #ECEAEA",
        }}
      >
        <OutlinedInput
          variant="outlined"
          placeholder="Write a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputRef={inputRef}
          multiline
          minRows={1}
          maxRows={5}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "none",
            "& fieldset": {
              border: "none",
            },
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            type="file"
            onChange={sendImage}
          />
          <label htmlFor="upload-image">
            <IconButton component="span">
              <img src={ic_show} style={{ height: 24, width: 24 }} />
            </IconButton>
          </label>
          <Box onClick={sendMessage} style={{ marginRight: 14, marginTop: 5 }}>
            <img src={ic_send} style={{ height: 24, width: 24 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MessageInput;
